import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import PublishIcon from "@mui/icons-material/Publish";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";

import "./style.scss";
import { APPLICATION_TYPE_EDETAIL } from "../ImageLayout/Constants";

export default function Dropdown(props) {
  let appType =
    props.fileDetails?.application_type ||
    props.fileContext?.file?.application_type;
  let fileDetails = props.fileDetails || props.fileContext?.file;
  const [age, setAge] = React.useState("");

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const publishBtnValidation = () => {
    return appType === 2
      ? props.disable // For Emailer
      : fileDetails?.publish_type !== null // For Edetail
      ? fileDetails?.publish_type !== 1
      : false;
  };

  const publishDspBtnValidation = () => {
    return fileDetails?.publish_type !== null
      ? fileDetails?.publish_type !== 2
      : false;
  };

  return (
    <div>
      <Button
        id="demo-customized-button"
        aria-controls={open ? "demo-customized-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
      >
        Export
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        className="action-dropdown"
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          value={10}
          disabled={publishBtnValidation()}
          onClick={() => {
            handleClose();
            appType === 2
              ? props.exportClicked()
              : props.setIsPublishClicked(1);
          }}
          disableRipple
        >
          <PublishIcon />
          PUBLISH
        </MenuItem>
        {appType === 2 ? (
          <>
            <MenuItem
              value={10}
              disabled={props.disable || appType === 1}
              onClick={() => {
                handleClose();
                props.exportResponsiveHTML();
              }}
              disableRipple
            >
              <PublishIcon />
              PUBLISH RESPONSIVE
            </MenuItem>
            <MenuItem
              value={20}
              onClick={() => {
                handleClose();
                props.downloadClicked(true);
              }}
              disabled={appType === 1}
              disableRipple
            >
              <DownloadForOfflineIcon />
              DOWNLOAD
            </MenuItem>
            <MenuItem
              value={20}
              onClick={() => {
                handleClose();
                props.downloadResponsiveHTML(true);
              }}
              disabled={appType === 1}
              disableRipple
            >
              <DownloadForOfflineIcon />
              RESPONSIVE HTML
            </MenuItem>
            <MenuItem
              value={20}
              onClick={() => {
                handleClose();
                props.setIsDownloadZip(true);
              }}
              disableRipple
            >
              <DownloadForOfflineIcon />
              DOWNLOAD ZIP
            </MenuItem>
          </>
        ) : (
          <>
            <MenuItem
              value={10}
              disabled={publishDspBtnValidation()}
              onClick={() => {
                handleClose();
                props.setIsPublishClicked(2);
              }}
              disableRipple
            >
              <PublishIcon />
              PUBLISH (DSP)
            </MenuItem>

            {/* {props.applicationType == APPLICATION_TYPE_EDETAIL ? */}
            <MenuItem
              value={20}
              onClick={() => {
                handleClose();
                props.setIsDownloadZip(true);
              }}
              disableRipple
            >
              <DownloadForOfflineIcon />
              DOWNLOAD ZIP
            </MenuItem>
            <MenuItem
              value={20}
              onClick={() => {
                handleClose();
                props.setIsDownloadZipDSP(true);
              }}
              disableRipple
            >
              <DownloadForOfflineIcon />
              DOWNLOAD ZIP (DSP)
            </MenuItem>
          </>
        )}

        {/* :
          null
        } */}
      </Menu>
    </div>
  );
}
