import React from "react";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import { keycloak } from "./services/keycloak";
import AppRouter from "./components/AppRouter/AppRouter";
import { ToastContainer } from "react-toastify";
import "./App.scss";

function App() {
  const tokenLogger = async (tokenData) => {
    if (tokenData.token) {
      window.localStorage.setItem("userToken", tokenData.token);
      window.localStorage.setItem(
        "userInfo",
        JSON.stringify(await getUserInfo())
      );
    }
  };
  const getUserInfo = async () => {
    try {
      return await keycloak.loadUserInfo();
    } catch (error) {
      console.log(error);
    }
  };

  const eventLogger = (event) => {};
  return (
    <div className="App">
      <ToastContainer />
      <ReactKeycloakProvider
        initOptions={{ onLoad: "login-required" }}
        authClient={keycloak}
        onEvent={eventLogger}
        onTokens={tokenLogger}
      >
        <AppRouter />
      </ReactKeycloakProvider>
    </div>
  );
}

export default App;
