import * as React from "react";
import { useContext } from "react";
import { useTheme } from "@mui/material/styles";
import {
  Box,
  Table,
  TableBody,
  TableRow,
  TableHead,
  TableContainer,
  TableFooter,
  TablePagination,
  Paper,
  Button,
  Typography,
  Tooltip,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import SearchIcon from "@mui/icons-material/Search";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import TextField from "@mui/material/TextField";
import { useNavigate } from "react-router-dom";
import InputBase from "@mui/material/InputBase";
import "./Styles.scss";
import { deleteFile, getListOfFiles } from "../../services/api";
import FileContext from "../../context/FileContext";
import {
  FileStatus,
  APPLICATION_TYPES,
  PUBLISH_TYPES,
} from "./FilesStatusEnum";
import DeletePrompt from "../prompts/DeletePrompt";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { StyledInputBase, Search, SearchIconWrapper } from "./StyledInput";
// import {
//   2,
//   0,
//   3,
//   1,
//   4,
// } from "./constant";

interface FilesProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number
  ) => void;
}

function Files(props: FilesProps) {
  const theme = useTheme();

  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

export default function CustomPaginationActionsTable() {
  const [page, setPage] = React.useState(0);
  const [files, setFiles] = React.useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const fileContext = useContext(FileContext);
  const [showDelete, setShowDelete] = React.useState(false);
  const [fileTobeDeleted, setFileTobeDeleted] = React.useState("");
  const [searchText, setSearchText] = React.useState("");
  const [totalFiles, setTotalFiles] = React.useState("");
  const navigate = useNavigate();
  const toastId = React.useRef(null);

  const getAllFiles = () => {
    let filesLimitToGet = rowsPerPage * (page + 1);

    // when user selects ALL option from dropdown then rowsPerPage value will be -1. So here we need to get all files
    if (filesLimitToGet === -1) {
      filesLimitToGet = totalFiles;
    }
    const body = {
      pageNumber: 0,
      limit: filesLimitToGet,
      searchText: searchText,
    };
    getListOfFiles(body)
      .then((res) => {
        const files = res?.data?.items || [];
        setTotalFiles(res?.data.totalCount);
        setFiles(files);
        if (!files.length) {
          if (!toast.isActive(toastId.current)) {
            toast.error("Sorry there are no files", {
              toastId: "files-search-error",
              pauseOnFocusLoss: false,
            });
          }
        }
      })
      .catch((err) => {});
  };

  React.useEffect(() => {
    fileContext.selectFile(null);
    fileContext.selectImageFile(null);
    localStorage.removeItem("file");
    localStorage.removeItem("imageFile");
    localStorage.removeItem("activeStep"); // whenever we are on dashboard we don't need to remember what was users last step.
  }, []);

  React.useEffect(() => {
    getAllFiles();
  }, [page, rowsPerPage]);

  const convertDateFormat = (timestamp) => {
    const DATE = new Date(timestamp);
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    // here we will format the date in the way we want
    const date =
      DATE.getDate() + " " + months[DATE.getMonth()] + " " + DATE.getFullYear();

    // here we will format the time in the way we want

    let hours = DATE.getHours();
    let minutes = DATE.getMinutes();
    let ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    const strTime = hours + ":" + minutes + " " + ampm;
    return date + " | " + strTime;
  };
  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - files.length) : 0;

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      width: "100vW",
      fontWeight: "600",
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      background: "#fafafa 0% 0% no-repeat padding-box",
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const viewFile = (file) => {
    fileContext.selectFile(file);
    // if(file.applicationType == 'Edetail'){
    navigate("/dynamic-layout/fileDetails");
    // }else{
    //     navigate('/dynamic-layout/AddFiles');
    // }
  };

  const goToAddFiles = () => {
    navigate("/dynamic-layout/AddFiles");
  };

  const getData = (e) => {
    e.preventDefault();
    setPage(0);
    getAllFiles();
  };

  const removeFile = (fileToDelete) => {
    deleteFile(fileToDelete.id)
      .then((res) => {
        toast.success(
          <div className="notificationText">File deleted successfully.!</div>
        );
        getAllFiles();
        // const remainingFiles = files.filter(
        //     (file) => fileToDelete.id != file.id
        // );
        // setFiles(remainingFiles);
      })
      .catch((err) => {
        toast.error(<div className="notificationText">Delete failed. !</div>);
      });
  };

  const checkIsDisabled = (file) => {
    let isDisabled = false;
    if (file.status === 0 && !file.html_file_path) {
      isDisabled = true;
    } else if (file.status === 4 && !file.html_file_path) {
      isDisabled = true;
    }
    return isDisabled;
  };

  return (
    <div className="myFilesContainer">
      {/* <ToastContainer /> */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          padding: "15px",
        }}
      >
        <span className="myFiles">{`My Files(${
          rowsPerPage > 0 ? rowsPerPage : totalFiles
        })`}</span>
        <Box>
          <form onSubmit={getData}>
            <Search>
              <div className="search-icon" onClick={getData}>
                <SearchIconWrapper>
                  <SearchIcon style={{ fill: "#034EA2" }} />
                </SearchIconWrapper>
              </div>
              <StyledInputBase
                placeholder="Search by file name"
                inputProps={{ "aria-label": "search" }}
                variant="outlined"
                className="text"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
              />
            </Search>
          </form>
        </Box>
      </Box>

      {files.length ? (
        <TableContainer component={Paper}>
          <Table
            sx={{ minWidth: 500, maxHeight: 440 }}
            stickyHeader
            aria-label="custom pagination table"
          >
            <TableHead>
              <TableRow>
                <StyledTableCell>
                  <span className="TableBodyText title">Request Details</span>
                </StyledTableCell>
                <StyledTableCell align="left">
                  <span className="TableBodyText title ">File Name</span>
                </StyledTableCell>
                <StyledTableCell align="left">
                  <span className="TableBodyText title">Uploaded on</span>
                </StyledTableCell>
                <StyledTableCell align="left">
                  <span className="TableBodyText title">Last updated</span>
                </StyledTableCell>
                <StyledTableCell align="left">
                  <span className="TableBodyText title">Type</span>
                </StyledTableCell>
                <StyledTableCell align="left">
                  <span className="TableBodyText title">Total Pages</span>
                </StyledTableCell>
                <StyledTableCell align="left">
                  <span className="TableBodyText title">Status</span>
                </StyledTableCell>
                <StyledTableCell align="left">
                  <span className="TableBodyText title">Action</span>
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(rowsPerPage > 0
                ? files.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : files
              ).map((file, index) => (
                <StyledTableRow key={file.id}>
                  <StyledTableCell align="left">
                    <div className="wrapper">
                      <img
                        className="requestDetailsImg"
                        src={require("../../Images/pdf.png")}
                        alt="logo"
                      />
                      <div>
                        <span className="TableBodyText title">{file.name}</span>
                        <Tooltip title={file.description}>
                          <div className="file-description" component="p">
                            {file.description}
                          </div>
                        </Tooltip>
                      </div>
                    </div>
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    <span className="TableBodyText breakWord">
                      {file.pdf_file_path.substring(
                        file.pdf_file_path.lastIndexOf("/") + 1
                      )}
                    </span>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <span className="TableBodyText">
                      {convertDateFormat(file.createdAt)}
                    </span>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <span className="TableBodyText">
                      {convertDateFormat(file.updatedAt)}
                    </span>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <span className="TableBodyText">
                      {`${APPLICATION_TYPES[file.application_type]} ${
                        file?.publish_type
                          ? `(${PUBLISH_TYPES[file?.publish_type]})`
                          : ""
                      }`}
                    </span>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <span className="TableBodyText">{file.num_pages}</span>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <span className={`status status-${file.status}`}>
                      {FileStatus[file.status]}{" "}
                    </span>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <button
                      disabled={checkIsDisabled(file)}
                      onClick={() => viewFile(file)}
                      className={`Actionbtn ${
                        checkIsDisabled(file) ? "disabled" : ""
                      }`}
                    >
                      View
                    </button>
                    <button
                      onClick={() => {
                        setFileTobeDeleted(file);
                        setShowDelete(true);
                      }}
                      className="Actionbtn"
                    >
                      Delete
                    </button>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
            <TableFooter>
              <TableRow align="left">
                <TablePagination
                  align="left"
                  rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                  count={totalFiles}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      "aria-label": "Items per page",
                    },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={Files}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      ) : (
        <div className="no-files-container">
          <Box>
            <img src={require("../../Images/no-files.png")} alt="logo" />
            <span className="noFileText">No files added yet!</span>
            <span onClick={goToAddFiles} className="addFileBtn">
              + Add Files
            </span>
          </Box>
        </div>
      )}
      {showDelete && (
        <DeletePrompt
          removeFile={removeFile}
          showDelete={showDelete}
          setShowDelete={setShowDelete}
          file={fileTobeDeleted}
          fileName={fileTobeDeleted.pdf_file_path.substring(
            fileTobeDeleted.pdf_file_path.lastIndexOf("/") + 1
          )}
        />
      )}
    </div>
  );
}
