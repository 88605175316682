import axios from "axios";

const baseURL = process.env.REACT_APP_API_ENDPOINT;
export const getListOfFiles = (body) => {
  const token = window.localStorage.getItem("userToken");

  return axios.post(baseURL + "/middleware/process/v1/get-all-files", body, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  });
};

export const getListOfPages = (body) => {
  const token = window.localStorage.getItem("userToken");

  return axios.post(baseURL + "/middleware/process/v1/get-all-pages", body, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  });
};

export const uploadFile = (fileObj) => {
  var formData = new FormData();
  formData.append("file", fileObj.file);
  formData.append("name", fileObj.fileName);
  formData.append("description", fileObj.fileDescription);
  formData.append("application_type", fileObj.applicationType);
  const token = window.localStorage.getItem("userToken");
  // setIsFileUploading(true);
  return axios.post(baseURL + "/middleware/upload/v4", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + token,
    },
  });
};

export const processHTML = (fileObj) => {
  const token = window.localStorage.getItem("userToken");
  return axios.post(
    // baseURL + "/middleware/upload/v3/processParallelHTML",  https://dev.genai-exp.indegene.ai/middleware/upload/v4/processParallelHTML
    baseURL + "/middleware/upload/v4/processParallelHTML",
    fileObj,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
};

export const getMuPDFData = (fileObj) => {
  const token = window.localStorage.getItem("userToken");
  return axios.post(
    baseURL + "/middleware/process/v1/extract_additional_elements",
    fileObj,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
};
export const getHTMLResponse = (body) => {
  const token = window.localStorage.getItem("userToken");
  return axios.post(baseURL + "/middleware/process/get-html-file", body, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  });
};

export const getFileDetails = (id, parentId) => {
  const token = window.localStorage.getItem("userToken");
  return axios.get(
    baseURL + "/middleware/process/get-file-details/" + id + "/" + parentId,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
};

export const getDynamicLayout = (body) => {
  const token = window.localStorage.getItem("userToken");
  return axios.post(baseURL + "/middleware/process/v3/generate-layout", body, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  });
};
export const getDynamicLayoutHTML = (body) => {
  const token = window.localStorage.getItem("userToken");
  return axios.post(
    baseURL + "/middleware/process/v3/generate-modified-layout",
    body,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
};

export const getPDFResponse = (fileURL) => {
  const token = window.localStorage.getItem("userToken");
  return axios.post(baseURL + "/middleware/process/get-html-file/" + fileURL, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  });
};

export const deleteFile = (comparisonId) => {
  const token = window.localStorage.getItem("userToken");
  return axios.delete(
    `${baseURL}/middleware/process/v2/delete-comparison/${comparisonId}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
};

export const getBrands = () => {
  const token = window.localStorage.getItem("userToken");
  return axios.get(baseURL + "/middleware/external/brands", {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  });
};

export const getRegions = () => {
  const token = window.localStorage.getItem("userToken");
  return axios.get(baseURL + "/middleware/external/regions", {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  });
};
export const getCampaigns = () => {
  const token = window.localStorage.getItem("userToken");
  return axios.get(baseURL + "/middleware/external/campaigns", {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  });
};
export const getCountries = () => {
  const token = window.localStorage.getItem("userToken");
  return axios.get(baseURL + "/middleware/external/countries", {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  });
};
export const getDevices = () => {
  const token = window.localStorage.getItem("userToken");
  return axios.get(baseURL + "/middleware/external-edetail/devices", {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  });
};

export const getDimensions = () => {
  const token = window.localStorage.getItem("userToken");
  return axios.get(baseURL + "/middleware/external-edetail/dimensions", {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  });
};

export const getMarket = () => {
  const token = window.localStorage.getItem("userToken");
  return axios.get(baseURL + "/middleware/external-edetail/markets", {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  });
};

export const getMasterSlides = (body) => {
  const token = window.localStorage.getItem("userToken");
  return axios.post(
    baseURL + "/middleware/external-edetail/masterSlides",
    body,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
};

export const publishPopupSlide = (body) => {
  const token = window.localStorage.getItem("userToken");
  return axios.post(
    baseURL + "/middleware/external-edetail/reorderSlide",
    body,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
};

export const getEdetailBrands = () => {
  const token = window.localStorage.getItem("userToken");
  return axios.get(baseURL + "/middleware/external-edetail/brands", {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  });
};

export const getEdetailCampaign = () => {
  const token = window.localStorage.getItem("userToken");
  return axios.get(baseURL + "/middleware/external-edetail/campaigns", {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  });
};

// https://dev.genai-exp.indegene.ai/middleware/external-edetail/slideData/${DeckID}
export const getSubmittedDetails = (deckId) => {
  const token = window.localStorage.getItem("userToken");
  return axios.get(
    baseURL + "/middleware/external-edetail/slideData/" + deckId,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
};

export const getEdetailZip = (filePath) => {
  const token = window.localStorage.getItem("userToken");
  return axios.get(
    baseURL + "/middleware/external-edetail/downloadZipFile/" + filePath,
    {
      headers: {
        "Content-Type": "application/zip",
        Authorization: "Bearer " + token,
      },
      responseType: "arraybuffer",
    }
  );
};

export const getDSPZip = (filePath) => {
  const token = window.localStorage.getItem("userToken");
  return axios.get(
    baseURL + "/middleware/external-edetail/downloadDSPZipFile/" + filePath,
    {
      headers: {
        "Content-Type": "application/zip",
        Authorization: "Bearer " + token,
      },
      responseType: "arraybuffer",
    }
  );
};

export const getEmailerZip = (body) => {
  const token = window.localStorage.getItem("userToken");
  return axios.post(
    baseURL + "/middleware/external/downloadZipFileEmailer",
    body,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      responseType: "arraybuffer",
    }
  );
};

export const publishHTML = (body) => {
  const token = window.localStorage.getItem("userToken");
  return axios.post(baseURL + "/middleware/external/publishHTML", body, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  });
};

export const publishResponsiveHTML = (body) => {
  const token = window.localStorage.getItem("userToken");
  return axios.post(
    baseURL + "/middleware/external/publishResponsiveHTML",
    body,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
};

export const publishEdetailHTML = (body) => {
  const token = window.localStorage.getItem("userToken");
  return axios.post(
    baseURL + "/middleware/external-edetail/createEdetail ",
    body,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
};

export const publishEdetailHTMLDsp = (body) => {
  const token = window.localStorage.getItem("userToken");
  return axios.post(
    baseURL + "/middleware/external-dsp-edetail/publishToDsp",
    body,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
};

export const validateFileName = (body) => {
  const token = window.localStorage.getItem("userToken");
  return axios.post(
    baseURL + "/middleware/process/v1/validate-file-name",
    body,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
};

export const intermediateSave = (body) => {
  const token = window.localStorage.getItem("userToken");
  return axios.post(
    baseURL + "/middleware/process/v2/intermediate-save",
    body,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
};

export const imageIntermediateSave = (body) => {
  const token = window.localStorage.getItem("userToken");
  return axios.post(
    // baseURL + "/middleware/upload/v3/intermediate-save ",
    baseURL + "/middleware/upload/v4/save-image-layout",
    body,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
};
