import React, { useState, useEffect, useContext, useRef } from "react";
import "./AddMetaData.scss";
import CustomizedSwitches from "./switch";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import { useNavigate } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import { ToastContainer, toast } from "react-toastify";
import {
  getBrands,
  getCampaigns,
  getCountries,
  getDevices,
  getDimensions,
  getEdetailBrands,
  getEdetailCampaign,
  getHTMLResponse,
  getMarket,
  getRegions,
  getSubmittedDetails,
  getEdetailZip,
  getEmailerZip,
  publishEdetailHTML,
  publishHTML,
  publishResponsiveHTML,
  getMasterSlides,
  publishPopupSlide,
  getDSPZip,
  publishEdetailHTMLDsp,
} from "../../services/api";
import FileContext from "../../context/FileContext";
import { tableHtml } from "./Tablehtml";
import {
  FILE_STATUS_COMPLETED,
  FILE_STATUS_INPROGRESS,
} from "../Files/constant";

import {
  APPLICATION_TYPE_EDETAIL,
  APPLICATION_TYPE_EMAILER,
  MJMLGenerated,
} from "../ImageLayout/Constants";

import { checkLeftRightCenter } from "./ResponsiveFunctions";
import { constructNewTable } from "./MultiColumn";
import { Button, List, ListItem, ListItemText } from "@mui/material";

const types = [
  { name: "Popups", _id: "697ca602-579f-4f3c-9a28-c0b199410f46", value: 2 },
];
function AddMetadata(props) {
  const [brands, setBrands] = useState([]);
  const [regions, setRegions] = useState([]);
  const [campaigns, setCampaigns] = useState([]);
  const [countries, setCountries] = useState([]);
  const [devices, setDevices] = useState([]);
  const [dimensions, setDimensions] = useState([]);
  const [market, setMarket] = useState([]);
  const [typesList] = useState(types);
  const [masterSlideList, setMasterSlideList] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState("");
  const [selectedRegion, setSelectedRegion] = useState("");
  const [selectedCampaigns, setSelectedCampaigns] = useState("");
  const [filteredCampaigns, setFilteredCampaigns] = useState([]);
  const [selectedCountries, setSelectedCountries] = useState("");
  const [filteredCountries, setFilteredCountries] = useState([]);
  const [selectedDevice, setSelectedDevice] = useState("");
  const [selectedDimension, setSelectedDimension] = useState("");
  const [selectedMarket, setSelectedMarket] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [selectedMasterSlide, setSelectedMasterSlide] = useState("");
  const [selectedMasterSlideData, setSelectedMasterSlideData] = useState({});
  const [emailNameValidity, setEmailNameValidity] = useState(true);
  const [emailName, setEmailName] = useState();

  const [isHTMLPublishing, setIsHTMLPublishing] = useState(false);
  const fileContext = useContext(FileContext);
  const [firstTablePadding, setFirstTablePadding] = useState(0);
  const [cssDesktop, setCssDesktop] = useState("");
  const [cssMedia, setCssMedia] = useState("");
  const [cssCount, setCssCount] = useState(0);
  const [htmlString, setHTMLString] = useState("");
  const [slideIndex, setSlideIndex] = useState(1);
  const [deckId, setDeckId] = useState(null);

  const navigate = useNavigate();
  const cssDesktopRef = useRef(cssDesktop);
  const cssCountRef = useRef(cssCount);

  const isReorder =
    fileContext.file?.file_conversion_pages[0]?.message.innerStatus
      .IS_REORDER !== false;

  const checkIfPublished = () => {
    //File is published in following scenarios
    // FIle status is completed
    // File status is In Progress but its inner status is MJML Generated
    return (
      FILE_STATUS_COMPLETED == fileContext.file?.file_conversion_pages[0].status
    );
  };
  const isDisabled = () => {
    if (props.fileDetails?.application_type === APPLICATION_TYPE_EDETAIL) {
      return deckId !== null;
    } else {
      return (
        FILE_STATUS_COMPLETED ==
        props.fileDetails?.file_conversion_pages[0].status
      );
    }
  };

  useEffect(() => {
    // ;
    props.setIsGJSLoading(true);
    let submittedData = "";

    if (fileContext.file.application_type === APPLICATION_TYPE_EDETAIL) {
      Promise.all([
        getEdetailBrands(),
        getEdetailCampaign(),
        getDevices(),
        getDimensions(),
        getMarket(),
      ])
        .then((values) => {
          setBrands(values[0].data);
          setCampaigns(values[1].data);
          setDevices(values[2].data);
          setDimensions(values[3].data);
          setMarket(values[4].data);
          props.setIsGJSLoading(false);
          if (values?.some((value) => !value?.data?.length))
            toast.error("Filed to fetch meta data");
          if (fileContext.file?.deck_id) {
            setDeckId(fileContext.file?.deck_id);
            props.setIsGJSLoading(true);
            getSubmittedDetails(fileContext.file?.deck_id)
              .then((res) => {
                props.setIsGJSLoading(false);
                submittedData = res.data;
                setSelectedBrand(submittedData.brand);
                setSelectedDevice(submittedData.device);
                setSelectedCampaigns(submittedData.campaign);
                setSelectedDimension(submittedData.dimension);
                setSelectedMarket(submittedData.market);
                setEmailName(submittedData.name);
                setSlideIndex(submittedData.slides?.length + 1 || 1);
                const slideData =
                  fileContext.file?.file_conversion_pages[0].slide_details;
                if (slideData !== null) {
                  const masterSlide = submittedData.slides.find(
                    (el) => el.slideid?._id === slideData.master_slide_id
                  );
                  setMasterSlideList(submittedData?.slides);
                  setSelectedType(slideData.type);
                  setSelectedMasterSlide(masterSlide.slideid?._id);
                }
              })
              .catch((err) => {
                props.setIsGJSLoading(false);
              });
          }
        })
        .catch((err) => {
          toast.error("Failed to fetch meta data");
          props.setIsGJSLoading(false);
        });
    } else {
      Promise.all([getBrands(), getRegions(), getCampaigns(), getCountries()])
        .then((values) => {
          props.setIsGJSLoading(false);
          setBrands(values[0].data);
          setRegions(values[1].data);
          setCampaigns(values[2].data);
          setCountries(values[3].data);
          if (values?.some((value) => !value?.data?.length))
            toast.error("Failed to fetch meta data..");
        })
        .catch((error) => {
          toast.error("Failed to fetch meta data");

          props.setIsGJSLoading(false);
        });
    }

    // Following else part will execute if this file is already published and user now coming here directly from dashboard
    // So for that we need to get html string from API
    if (props.userGeneratedHTML) {
      setHTMLString(props.userGeneratedHTML.htmlContent);
    } else {
      if (fileContext?.file) {
        getHTMLResponse({
          s3_html_url:
            fileContext?.file?.file_conversion_pages[0]?.user_html_file_path,
        }).then((res) => {
          setHTMLString(res.data);
        });
      }
    }
    if (checkIfPublished()) {
      props.setIsBackDisabled(true);
    }

    setEmailName("Name");
  }, []);

  // useEffect(() => {
  //   const handleBeforeUnload = (event) => {
  //     window.localStorage.setItem("activeStep", 3);
  //     event.preventDefault();
  //     event.returnValue = ""; // Required for Chrome to show confirmation dialog
  //     window.addEventListener("beforeunload", handleBeforeUnload);
  //   };

  //   return () => {
  //     window.removeEventListener("beforeunload", handleBeforeUnload);
  //   };
  // }, []);

  useEffect(() => {
    if (props.downloadHTML) {
      let htmlContent = props.userGeneratedHTML.htmlContent || htmlString;
      if (props.fileDetails?.application_type === APPLICATION_TYPE_EDETAIL)
        htmlContent = props.upScaleImageAndFontSize(
          props.userGeneratedHTML.htmlContntWithWrapper
        );

      // // Log the HTML content to the console (you can perform further actions here)
      // console.log("Exported HTML content:", htmlContent);

      // Example: Save HTML content to a file
      // You can use any method to save the content, like Blob, FileSaver.js, etc.
      var blob = new Blob([htmlContent], { type: "text/html" });
      var link = document.createElement("a");
      let fileName;
      if (props.fileDetails) {
        fileName = props.fileDetails.name;
      } else {
        fileName = fileContext.file.name;
      }
      link.href = window.URL.createObjectURL(blob);

      link.download = fileName + ".html";
      link.click();
      props.downloadClicked(false);
    }
  }, [props.downloadHTML]);

  useEffect(() => {
    window.localStorage.setItem("CSSDesktop", "");
    window.localStorage.setItem("CSSMedia", "");
    window.localStorage.setItem("CSSCount", 0);
    window.localStorage.setItem("tableCount", 0);
    if (props.responsiveHtml) {
      let htmlContent = handleResponsiveHTML();
      // // Log the HTML content to the console (you can perform further actions here)
      // console.log("Exported HTML content:", htmlContent);
      // Example: Save HTML content to a file
      // You can use any method to save the content, like Blob, FileSaver.js, etc.
      var blob = new Blob([htmlContent], { type: "text/html" });
      var link = document.createElement("a");

      let fileName;
      if (props.fileDetails) {
        fileName = props.fileDetails.name;
      } else {
        fileName = fileContext.file.name;
      }
      link.href = window.URL.createObjectURL(blob);

      link.download = fileName + "_responsive.html";

      link.click();
      props.downloadResponsiveHTML(false);
    }
  }, [props.responsiveHtml]);

  useEffect(() => {
    let HTML_CONTENT;

    if (props.isDownloadZip) {
      let fileName, userHTMLFilePath;
      if (props.fileDetails) {
        fileName = props.fileDetails.name;
        userHTMLFilePath =
          props.fileDetails?.file_conversion_pages[0]?.scaled_html_file_path;
      } else {
        fileName = fileContext.file.name;
        userHTMLFilePath =
          fileContext?.file?.file_conversion_pages[0]?.scaled_html_file_path;
      }
      if (fileContext.file.application_type === APPLICATION_TYPE_EDETAIL) {
        getEdetailZip(userHTMLFilePath)
          .then((res) => {
            handleDownloadZip(res, fileName);
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        let htmlContent = handleResponsiveHTML();
        getEmailerZip({ htmlContent: htmlContent })
          .then((res) => {
            handleDownloadZip(res, fileName);
          })
          .catch((err) => {
            console.log(err);
          });
      }
      props.downloadZip(false);
    }
  }, [props.isDownloadZip]);
  useEffect(() => {
    let HTML_CONTENT;

    if (props.isDownloadZipDSP) {
      let fileName, userHTMLFilePath, uuid, fileId;
      if (props.fileDetails) {
        fileName = props.fileDetails.name;
        uuid = props.fileDetails.uuid;
        userHTMLFilePath =
          props.fileDetails?.file_conversion_pages[0]?.scaled_html_file_path;
        fileId = props.fileDetails?.file_conversion_pages[0]?.id;
      } else {
        fileName = fileContext.file.name;
        uuid = fileContext.file.uuid;
        userHTMLFilePath =
          fileContext?.file?.file_conversion_pages[0]?.scaled_html_file_path;
        fileId = fileContext?.file?.file_conversion_pages[0]?.id;
      }
      if (fileContext.file.application_type === APPLICATION_TYPE_EDETAIL) {
        userHTMLFilePath = `${userHTMLFilePath}/${fileId}`;
        getDSPZip(userHTMLFilePath)
          .then((res) => {
            handleDownloadZip(res, fileName);
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        let htmlContent = handleResponsiveHTML();
        getEmailerZip({ htmlContent: htmlContent })
          .then((res) => {
            handleDownloadZip(res, fileName);
          })
          .catch((err) => {
            console.log(err);
          });
      }
      props.downloadZipDSP(false);
    }
  }, [props.isDownloadZipDSP]);

  const publishAsPopupSlide = async (childIndex) => {
    try {
      const body = {
        deck_id: props.fileDetails.deck_id,
        master_id: selectedMasterSlideData.id,
        child_id: props.fileDetails?.file_conversion_pages[0].id,
        conversion_id: props.fileDetails.id,
        child_slide_index: childIndex,
        master_slide_index: selectedMasterSlideData.index,
        master_slide_id: selectedMasterSlide,
        child_slide_id: props.fileDetails?.file_conversion_pages[0].slide_id,
        type: selectedType,
      };
      await publishPopupSlide(body);
      toast.success("Popup Slide Published Successfully.");
    } catch (error) {
      toast.error(error.response.data);
    }
  };

  useEffect(() => {
    if (props.publishHTML > 0) {
      setIsHTMLPublishing(true);
      if (document.getElementById("publish-btn")) {
        document.getElementById("publish-btn").disabled = true;
      }
      if (props.fileDetails?.application_type === APPLICATION_TYPE_EDETAIL) {
        if (isReorder) {
          let htmlContent = props.upScaleImageAndFontSize(
            props.userGeneratedHTML.htmlContentWithWrapper
          );
          const body = {
            htmlContent: htmlContent,
            name: emailName?.trim(),
            brand: selectedBrand,
            campaign: selectedCampaigns,
            market: selectedMarket,
            dimension: selectedDimension,
            device: selectedDevice,
            id: props.fileDetails?.file_conversion_pages[0]?.id,
            parent_id:
              props.fileDetails?.file_conversion_pages[0]?.conversion_id,
            uuid: props.fileDetails?.uuid || fileContext.file?.uuid,
            deck_id: props.fileDetails.deck_id,
            slideIndex: slideIndex,
            type: selectedType,
          };

          publishEdetailHTML(body)
            .then((res) => {
              if (document.getElementById("publish-btn")) {
                document.getElementById("publish-btn").disabled = false;
              }
              setTimeout(() => {
                if (selectedType && selectedMasterSlide) {
                  publishAsPopupSlide(
                    res.data.file_conversion_pages[0].slide_index
                  );
                }
                setIsHTMLPublishing(false);
                toast.success("File Published Successfully");
                navigate("/dynamic-layout/fileDetails");
              }, 3000);
            })
            .catch((err) => {
              setIsHTMLPublishing(false);
              toast.error(err.response.data);
              if (document.getElementById("publish-btn"))
                document.getElementById("publish-btn").disabled = false;
            });
        } else {
          publishAsPopupSlide(
            props.fileDetails?.file_conversion_pages[0].slide_index
          );
          navigate("/dynamic-layout/fileDetails");
        }
      } else {
        const body = {
          htmlContent: props.userGeneratedHTML?.htmlContent || htmlString,
          name: emailName?.trim(),
          region: selectedRegion,
          brand: selectedBrand,
          campaign: selectedCampaigns,
          country: selectedCountries,
          conversion_id: props.fileDetails?.id || fileContext.file?.id,
          id:
            props.fileDetails?.file_conversion_pages[0]?.id ||
            fileContext.file?.file_conversion_pages[0]?.id,
          uuid: props.fileDetails?.uuid || fileContext.file?.uuid,
        };
        publishHTML(body)
          .then((res) => {
            setIsHTMLPublishing(false);
            if (document.getElementById("publish-btn")) {
              document.getElementById("publish-btn").disabled = false;
            }
            toast.success(res?.data);
            navigate("/dynamic-layout/fileDetails");
          })
          .catch((err) => {
            setIsHTMLPublishing(false);
            toast.error("Something went wrong. We will look into it");
            if (document.getElementById("publish-btn"))
              document.getElementById("publish-btn").disabled = false;
            navigate("/dynamic-layout/fileDetails");
          });
      }
    }
  }, [props.publishHTML]);

  useEffect(() => {
    if (props.publishHTMLDSP > 0) {
      setIsHTMLPublishing(true);
      if (props.fileDetails?.application_type === APPLICATION_TYPE_EDETAIL) {
        let htmlContent = props.upScaleImageAndFontSize(
          props.userGeneratedHTML.htmlContentWithWrapper
        );
        const body = {
          presentationId: props.fileDetails.deck_id,
          pageName: getPageAndFileName("page"),
          userEmail: getUserEmail()?.trim(),
          fileName: getPageAndFileName("file"),
          id: props.fileDetails?.file_conversion_pages[0]?.id,
          slideIndex: slideIndex,
          parent_id: props.fileDetails?.file_conversion_pages[0]?.conversion_id,
          uuid: props.fileDetails?.uuid || fileContext.file?.uuid,
          htmlContent: htmlContent,
        };
        publishEdetailHTMLDsp(body)
          .then((res) => {
            setIsHTMLPublishing(false);
            toast.success("File Published Successfully");
            navigate("/dynamic-layout/fileDetails");
          })
          .catch((err) => {
            setIsHTMLPublishing(false);
            toast.error(err.response.data);
          });
      }
    }
  }, [props.publishHTMLDSP]);

  useEffect(() => {
    if (props.publishResponsiveHTML > 0) {
      setIsHTMLPublishing(true);
      let htmlContent = handleResponsiveHTML();

      const body = {
        htmlContent: htmlContent || htmlString,
        name: emailName?.trim(),
        region: selectedRegion,
        brand: selectedBrand,
        campaign: selectedCampaigns,
        country: selectedCountries,
        conversion_id: props.fileDetails?.id || fileContext.file?.id,
        id:
          props.fileDetails?.file_conversion_pages[0]?.id ||
          fileContext.file?.file_conversion_pages[0]?.id,
        uuid: props.fileDetails?.uuid || fileContext.file?.uuid,
      };

      publishResponsiveHTML(body).then((res) => {
        toast.success(res?.data.message);
        navigate("/dynamic-layout/fileDetails");
        setIsHTMLPublishing(false);
      });
    }
  }, [props.publishResponsiveHTML]);

  useEffect(() => {
    if (fileContext.file.application_type === APPLICATION_TYPE_EDETAIL) {
      const isSlidePopup = selectedType ? selectedMasterSlide !== "" : true;
      if (
        isReorder
          ? selectedBrand &&
            selectedDevice &&
            selectedCampaigns &&
            selectedDimension &&
            selectedMarket &&
            emailName?.trim() &&
            !checkIfPublished() &&
            emailNameValidity &&
            isSlidePopup
          : selectedType && selectedMasterSlide
      ) {
        fileContext.setPublishButtonDisabled(false);
      } else {
        fileContext.setPublishButtonDisabled(true);
      }
    } else {
      if (
        selectedBrand &&
        selectedRegion &&
        selectedCampaigns &&
        selectedCountries &&
        emailName?.trim() &&
        !checkIfPublished() &&
        emailNameValidity
      ) {
        fileContext.setPublishButtonDisabled(false);
      } else {
        fileContext.setPublishButtonDisabled(true);
      }
    }
  }, [
    selectedBrand,
    selectedRegion,
    selectedCampaigns,
    selectedCountries,
    selectedDimension,
    selectedDevice,
    selectedMarket,
    emailName,
    selectedType,
    selectedMasterSlide,
  ]);

  useEffect(() => {
    if (selectedBrand) {
      onChangeBrand(selectedBrand);
    }
  }, [selectedBrand]);

  useEffect(() => {
    cssCountRef.current = cssCount;
  }, [cssCount]);

  useEffect(() => {
    if (props.fileDetails?.publish_type) {
      props.setIsPublishClicked(props.fileDetails?.publish_type);
    }
  }, [props.fileDetails?.publish_type]);
  const handleResponsiveHTML = () => {
    let html = responsiveHTML();
    return tableHtml(
      html.tblParent.outerHTML,
      window.localStorage.getItem("CSSDesktop"),
      window.localStorage.getItem("CSSMedia")
    );
  };
  const handleDownloadZip = (res, fileName) => {
    const url = window.URL.createObjectURL(
      new Blob([res.data], { type: "application/zip" })
    );
    const link = document.createElement("a");
    link.href = url;

    fileName = fileName + ".zip";
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  };

  const onChangeBrand = (brand) => {
    let filteredCampaigns = campaigns.filter((campaign) => {
      const campaignBrand = campaign?.brandid?.[0] || campaign.brand_name;
      return campaignBrand == brand;
    });

    if (!filteredCampaigns.length) {
      const dummyBrand = {
        brand_id: "62ea292414e810483a440a3a",
        brand_name: "Brand A",
        id: "62ea29e414e810483a440a3d",
        name: "NA",
      };
      filteredCampaigns.push(dummyBrand);
    }
    setSelectedBrand(brand);
    setFilteredCampaigns(filteredCampaigns);
  };

  const onRegionChange = (selectedRegionName) => {
    const region = regions.filter((region) => {
      return region.name == selectedRegionName;
    })[0];
    let filteredCountries = countries.filter((country) => {
      return country.regionId == region?.id;
    });

    if (!filteredCountries.length) {
      const dummyCountry = {
        region_id: "62ea292414e810483a440a3a",
        region_name: "NA",
        id: "62ea29e414e810483a440a3d",
        name: "NA",
      };
      filteredCountries.push(dummyCountry);
    }
    setSelectedRegion(selectedRegionName);
    setFilteredCountries(filteredCountries);
  };

  const clearSelection = () => {
    setSelectedBrand("");
    setSelectedCampaigns("");
  };

  const emailNameChange = (e) => {
    const alphaNumericRegex = /^[a-zA-Z0-9\-\s]+$/;
    if (alphaNumericRegex.test(e.target.value)) {
      setEmailNameValidity(true);
    } else {
      setEmailNameValidity(false);
    }
    setEmailName(e.target.value);
  };

  const cleanSections = (Sections) => {
    var children = Array.from(Sections.childNodes);

    children.forEach((child) => {
      if (child.style.width === "" && !child.hasAttribute("child")) {
        child.remove();
      }
    });
  };

  const responsiveHTML = () => {
    // let cssDesktop = "";
    // let cssMedia = "";
    // let cssCount = 0;
    let topMostParent = document.querySelector(".user-generated-html");

    let tblParent = document.createElement("table");
    tblParent.setAttribute("cellspacing", "0");
    tblParent.setAttribute("cellpadding", "0");
    tblParent.style.width = "600px";
    tblParent.style.backgroundColor = "#fff";
    tblParent.className = "resp-table";
    // First Step: Top most parent child navigation //RedLines
    topMostParent.childNodes.forEach((Sections, index) => {
      //commented it bcoz the backgroundColor should be for tr
      //trParent.style.background = Sections.style.backgroundColor;
      //tblParent.style.backgroundColor = "lightblue";

      // Second Step: how many columns does this section has

      //let columnsArray = checkforcolumn(Sections);

      //second step:check for empty divs

      cleanSections(Sections);

      if (
        Sections.querySelectorAll("[coulumn]").length &&
        Sections.querySelectorAll("[coulumn]")[0].attributes.coulumn.value ===
          "multi"
      ) {
        // if many columns || columnsArray?.column.length
        // let columnsArray = checkforcolumn(Sections);
        let trParent = tblParent.insertRow();
        trParent.setAttribute("name", "MainContainer");
        let tableCount = JSON.parse(window.localStorage.getItem("tableCount"));
        trParent.setAttribute("number", tableCount);
        window.localStorage.setItem("tableCount", tableCount + 1);
        addPaddingToMainContainerTr(trParent);

        let tdParent = trParent.insertCell();
        tdParent.setAttribute("align", "center");

        // tr.style.backgroundColor = "lightgreen";

        let tableColum = constructNewTable(Sections);
        tdParent.style.backgroundColor = Sections.style.backgroundColor;
        tdParent.append(tableColum);
      } else {
        // Signle Column layout

        let tableSingleColumn = singleColumnLayout(
          Sections,
          tblParent
          // cssDesktop,
          // cssMedia,
          // cssCount
        );
        // tableSingleColumn.forEach((tableRows) => {
        //   td.append(tableRows);
        // });
      }

      // tdParent.append(tbl);
    });
    setCssCount(cssCount + 1);
    return { tblParent };
    // document.getElementById("tableDom").appendChild(tblParent);
  };

  function removeLastTagChar(inputString) {
    // Regular expression to match HTML tags
    const regex = /<[^>]*>/g;

    // Use replace method to remove all HTML tags
    return inputString.replace(regex, "");
  }

  function checkForSpecificCharacter(currentLine, nextLine) {
    var regex = /@|\.com|custom|Subject|userEmailAddress/g;

    // Test the sentence against the regular expression
    // if (currentLine.includes("allergic"))  ;

    return regex.test(currentLine) || regex.test(nextLine);
  }
  const check4Sentences = (yellowinnerChild) => {
    const lines = yellowinnerChild.childNodes;
    let firstLinePara = false;
    for (let i = 0; i < lines.length - 1; i++) {
      const currentLine = removeLastTagChar(lines[i].innerHTML.trim());

      const nextLine = removeLastTagChar(lines[i + 1].innerHTML.trim());

      const lastCharIndex = currentLine.length - 1;
      // if (currentLine.includes("allergic"))  ;
      if (
        (currentLine.charAt(lastCharIndex) === "." &&
          /^[A-Z]/.test(nextLine)) ||
        checkForSpecificCharacter(currentLine, nextLine)
      ) {
        // if (currentLine.includes("allergic")) ;
        if (firstLinePara) {
          return false;
        } else if (
          i < 1 &&
          lines.length > 2 &&
          !checkForSpecificCharacter(currentLine, nextLine)
        )
          firstLinePara = true;
        else return false;
      }
    }
    return true;
  };
  const lookforParaVsline = (
    yellowinnerChild,
    tbInner,
    Sections,
    tdParent
    // cssDesktop,
    // cssMedia,
    // cssCount
  ) => {
    //assuming para will be greater than 300
    if (
      (parseInt(yellowinnerChild.style?.width) > 300 &&
        yellowinnerChild?.childNodes?.length > 1 &&
        yellowinnerChild?.attributes?.blocktype?.value === "LAYOUT_TEXT" &&
        check4Sentences(yellowinnerChild)) ||
      parseInt(yellowinnerChild?.style?.width) > 500
    ) {
      let trInnerRow = tbInner.insertRow();
      let tdInnerTD = trInnerRow.insertCell();
      let getValue = checkLeftRightCenter(
        yellowinnerChild,
        tdParent,
        firstTablePadding,
        tbInner
      );
      tdParent.style.backgroundColor = Sections?.style?.backgroundColor;
      let collectPara = "";
      yellowinnerChild.childNodes.forEach((insideyellow, indexInsideyellow) => {
        collectPara = collectPara + insideyellow.innerHTML + " ";

        // Last child and section bottom gap
        if (indexInsideyellow === yellowinnerChild?.childNodes?.length - 1) {
          let value =
            //  parseInt(Sections.style.top) -
            Sections.clientHeight -
            (Sections.childNodes[Sections.childNodes.length - 1].clientHeight +
              parseInt(
                Sections.childNodes[Sections.childNodes.length - 1].style.top
              ));

          let tr = tbInner.insertRow();
          let tdforTR = tr.insertCell();
          tdforTR.style.height = value + "px";
          // tdforTR.style.backgroundColor = "black";
        }
      });

      tdInnerTD.innerHTML = collectPara;

      tdInnerTD.style = yellowinnerChild.childNodes[0]?.style?.cssText;
      tdInnerTD.style.removeProperty("top");
      tdInnerTD.style.removeProperty("left");
      tdInnerTD.style.lineHeight =
        parseInt(yellowinnerChild.childNodes[0]?.style?.fontSize) + 6 + "px" ||
        "15px";
      trInnerRow.style.backgroundColor =
        yellowinnerChild.style?.backgroundColor;
      return tbInner;
    }
    return false;
  };

  const constructCTA = (
    yellowinnerChild,
    tdParent,
    firstTablePadding,
    tbInner,
    Sections
  ) => {
    let retVal = checkLeftRightCenter(
      yellowinnerChild,
      tdParent,
      firstTablePadding,
      tbInner
    );
    tdParent.style.backgroundColor = Sections?.style?.backgroundColor;
    let trInnerRowCTA = tbInner.insertRow();
    let tdInnerTDCTA = trInnerRowCTA.insertCell();

    tdInnerTDCTA.innerHTML = yellowinnerChild.childNodes[0].outerHTML;
    // yellowinnerChild.childNodes[0].style.textAlign = "center";

    // yellowinnerChild.childNodes[0].style.display = "block"; // needs confirmation from BU team
  };

  const constructTable = (yellowinnerChild, insideyellow, tdInnerTD) => {
    Array.from(yellowinnerChild.children).forEach((ele) => {
      if (ele.tagName !== "TABLE" && !ele?.innerHTML) {
        yellowinnerChild.removeChild(ele);
      }
    });
    const table = insideyellow.cloneNode(true);
    table.style.textAlign = "center";
    tdInnerTD.append(table);
  };

  // const cunstructList = (insideyellow) => {
  //   Array.from(insideyellow.children).forEach((ele, i) => {
  //     let CSSMedia = window.localStorage.getItem("CSSMedia");
  //     let CSSCount = JSON.parse(window.localStorage.getItem("CSSCount"));

  //     let displayInline = `displayInline${CSSCount}`;
  //     let EleStyle = `.${displayInline}{
  //       display: inline;
  //     }\n`;
  //     CSSMedia = CSSMedia + EleStyle;
  //     ele.className = displayInline;
  //     CSSCount = CSSCount + 1;
  //     if (i !== insideyellow.children.length - 1) {
  //       ele.innerHTML = ele.innerHTML + " ";
  //     }
  //     window.localStorage.setItem("CSSMedia", CSSMedia);
  //     window.localStorage.setItem("CSSCount", CSSCount);
  //     ele.style.textAlign = "";
  //     if (i !== 0) {
  //       ele.style.paddingLeft = "8px";
  //     }
  //   });
  // };

  const cunstructList = (insideyellow, tbInner) => {
    const tr = tbInner.insertRow();
    tr.style.backgroundColor = insideyellow.style?.backgroundColor;
    let isListItem = true;

    insideyellow.childNodes[0]?.childNodes.forEach((ele, i) => {
      if (isListItem) {
        const td = tr.insertCell();
        const style = insideyellow.childNodes[0].style;
        if (i === 0) {
          const bulletPoint = ele.innerHTML || ele.nodeValue;
          if (bulletPoint.includes("•")) {
            td.setAttribute("width", "12");
            td.setAttribute("valign", "top");
            td.innerHTML = bulletPoint;
          } else {
            const td2 = tr.insertCell(0);
            td2.setAttribute("width", "12");
            td.innerHTML = insideyellow.childNodes[0].innerHTML;
            isListItem = false;
          }
        } else {
          if (ele.innerHTML) td.innerHTML = ele.innerHTML;
          else td.remove();
        }
        td.style.fontSize = style.fontSize;
        td.style.fontFamily = style.fontFamily;
        td.style.color = style.color;
        td.style.fontWeight = style.fontWeight;
      }
    });
  };

  const constructLayoutWithText = (yellowinnerChild, tdParent, tbInner) => {
    checkLeftRightCenter(
      yellowinnerChild,
      tdParent,
      firstTablePadding,
      tbInner
    );
    const image = yellowinnerChild.querySelectorAll("img")[0];
    let tr = tbInner.insertRow();
    tr.style.backgroundImage = `url(${image.src})`;
    tr.style.backgroundSize = "cover";
    let td = tr.insertCell();

    let table = document.createElement("table");
    table.id = "mainTbl";
    table.setAttribute("cellspacing", "0");
    table.setAttribute("cellpadding", "0");

    const generateColGap = (
      table,
      clientWidth,
      eleLeft,
      eleWidth,
      isReturn
    ) => {
      const tBody = table.getElementsByTagName("tbody")[0];
      if (tBody) {
        const lastTr = tBody.childNodes[tBody.childNodes.length - 1];
        const gap = clientWidth - (eleLeft + eleWidth);
        if (gap > 0) {
          const lastTd = lastTr.insertCell();
          lastTd.setAttribute("width", gap);
        }

        if (isReturn) return lastTr;
      }
    };

    const insertTrTdAndContent = (table, eleLeft, eleWidth, element) => {
      const tr = table.insertRow();
      const td = tr.insertCell();
      td.setAttribute("width", eleLeft);

      const td2 = tr.insertCell();
      td2.setAttribute("width", eleWidth);
      td2.innerHTML = element.outerHTML;
    };

    const generateRowGap = (table, gap) => {
      if (gap > 0) {
        const tr = table.insertRow();
        tr.setAttribute("height", gap);
      }
    };

    yellowinnerChild.childNodes.forEach((element, i) => {
      if (element.tagName !== "IMG") {
        let eleTop = parseInt(element.style.top);
        let eleLeft = parseInt(element.style.left);
        let eleWidth = element.clientWidth;

        if (i == 0) {
          generateRowGap(table, eleTop);
          insertTrTdAndContent(table, eleLeft, eleWidth, element);
        } else {
          const prevEle = yellowinnerChild.childNodes[i - 1];
          const prevEleWidth = prevEle.clientWidth;
          const prevEleLeft = parseInt(prevEle.style.left);
          const prevEleTop = parseInt(prevEle.style.top);
          const prevEleHeight = prevEle.clientHeight;
          const isColumn =
            prevEleWidth + prevEleLeft < eleLeft &&
            prevEleHeight + prevEleTop > eleTop;

          if (isColumn) {
            const lastTr = generateColGap(
              table,
              eleLeft,
              prevEleLeft,
              prevEleWidth,
              true
            );
            const td2 = lastTr.insertCell();
            td2.setAttribute("width", eleWidth);
            td2.innerHTML = element.outerHTML;
          } else {
            generateColGap(
              table,
              yellowinnerChild.clientWidth,
              prevEleLeft,
              prevEleWidth,
              false
            );
            generateRowGap(table, eleTop - (prevEleHeight + prevEleTop));
            insertTrTdAndContent(table, eleLeft, eleWidth, element);
          }
        }
        if (yellowinnerChild.childNodes.length - 2 === i) {
          generateColGap(
            table,
            yellowinnerChild.clientWidth,
            eleLeft,
            eleWidth,
            false
          );
          generateRowGap(
            table,
            yellowinnerChild.clientHeight - (element.clientHeight + eleTop)
          );
        }
      }
    });
    let tbody = table.getElementsByTagName("tbody")[0];
    tbody.style.display = "grid";
    td.append(table);
  };

  const removeBackgroundColor = (section, tbMainParent) => {
    let trMainParent = tbMainParent.insertRow();
    trMainParent.setAttribute("name", "MainContainer");
    let tdMainCell = trMainParent.insertCell();
    tdMainCell.setAttribute("height", parseFloat(section.style.height));
  };

  //Default 20px left and right padding requested from BU team for responsive HTML.
  const addPaddingToMainContainerTr = (trMainParent) => {
    trMainParent.style.display = "grid";
    trMainParent.style.paddingLeft = "20px";
    trMainParent.style.paddingRight = "20px";
  };

  const singleColumnLayout = (
    Sections,
    tbMainParent
    // cssDesktop,
    // cssMedia,
    // cssCount
  ) => {
    let collectTable = [];
    if (!Sections.childNodes?.length) {
      removeBackgroundColor(Sections, tbMainParent);
    }
    //call a function when inner childs are not there
    Sections.childNodes.forEach((yellowinnerChild, yellowChildIndex) => {
      // check for li-comp
      //;

      let trMainParent = tbMainParent.insertRow();
      addPaddingToMainContainerTr(trMainParent);
      trMainParent.setAttribute("name", "MainContainer");
      let tableCount = JSON.parse(window.localStorage.getItem("tableCount"));
      trMainParent.setAttribute("number", tableCount);
      window.localStorage.setItem("tableCount", tableCount + 1);
      let tdParent = trMainParent.insertCell();
      tdParent.style.borderRadius = Sections.style?.borderRadius;

      let tbInner = document.createElement("table");
      // tbInner.className = "sriPada";
      if (
        yellowChildIndex === 0 &&
        checktopGapBetweenDivs(yellowinnerChild, Sections) > 3
      ) {
        //commented this if as 2 column issue for 2 yellow

        let trInner = tbInner.insertRow();
        let tdInner = trInner.insertCell();
        tdInner.style.height =
          checktopGapBetweenDivs(yellowinnerChild, Sections) + "px";
        //tdInner.style.background = "grey";
      }

      tbInner.setAttribute("cellspacing", "0");
      tbInner.setAttribute("cellpadding", "0");
      tbInner.style.borderCollapse = "separate";
      //tbInner.style.width = "100%";

      // second leve: goinside the yellow children

      //check if para is left, right, center or needs padding

      // if (parseInt(yellowinnerChild.style.left) - firstTablePadding > 0) {
      //   //LookFor left Gap
      //   let td = tr.insertCell();
      //   td.style.width =
      //     parseInt(yellowinnerChild.style.left) - firstTablePadding + "px";
      //   td.className = "leftPadding";
      //   // td.style.backgroundColor = "red";
      // }

      let yellowInnerAttr = yellowinnerChild.getAttribute("blocktype");
      if (
        yellowInnerAttr === "LAYOUT_TEXT" &&
        lookforParaVsline(yellowinnerChild, tbInner, Sections, tdParent)
      ) {
      } else if (
        yellowInnerAttr === "YOLO_CTA" &&
        yellowinnerChild?.childNodes?.length //commented to commit
      ) {
        constructCTA(
          yellowinnerChild,
          tdParent,
          firstTablePadding,
          tbInner,
          Sections
        );
      } else if (yellowInnerAttr === "LAYOUT_FIGURE_WITH_TEXT") {
        constructLayoutWithText(yellowinnerChild, tdParent, tbInner);
      } else {
        yellowinnerChild.childNodes.forEach(
          (insideyellow, indexInsideyellow) => {
            if (
              indexInsideyellow === 0 &&
              checktopGapBetweenDivs(yellowinnerChild, Sections) > 3
            ) {
              let tbInnerRow = tbInner.insertRow();
              let tdforTR = tbInnerRow.insertCell();
              tdforTR.style.height =
                checktopGapBetweenDivs(insideyellow, yellowinnerChild) + "px";
            }

            if (insideyellow.tagName === "IMG") {
              var imgTag = document.createElement("IMG");

              imgTag.setAttribute("src", insideyellow.currentSrc);
              imgTag.setAttribute("width", insideyellow.width);
              imgTag.setAttribute("alt", insideyellow.alt);
              imgTag.style.opacity = insideyellow.style?.opacity;
              imgTag.style.borderRadius = insideyellow.style?.borderRadius;
              imgTag.style.border = insideyellow.style?.border;

              let trInnerRow = tbInner.insertRow();
              let tdInnerTD = trInnerRow.insertCell();

              // to make image at center
              tdInnerTD.setAttribute("align", "center");
              checkLeftRightCenter(
                yellowinnerChild,
                tdParent,
                firstTablePadding,
                tbInner
              );

              if (parseInt(insideyellow.width) > 350)
                imgTag.className = "resp-img";
              parseInt(yellowinnerChild.style.left) > 50 &&
              parseInt(yellowinnerChild.style.left) < 300
                ? (tdInnerTD.style.textAlign = "center")
                : (tdInnerTD.style.textAlign = "left");

              if (parseInt(yellowinnerChild.style.left) > 350)
                tdInnerTD.style.textAlign = "right";
              // Last child and section bottom gap
              if (
                indexInsideyellow ===
                yellowinnerChild.childNodes.length - 1
              ) {
                let value =
                  //  parseInt(Sections.style.top) -
                  Sections.clientHeight -
                  (Sections.childNodes[Sections.childNodes.length - 1]
                    .clientHeight +
                    parseInt(
                      Sections.childNodes[Sections.childNodes.length - 1].style
                        .top
                    ));

                let trInner = tbInner.insertRow();
                let tdInner = trInner.insertCell();
                tdInner.style.height = value + "px";
                //  tdforTR.style.backgroundColor = "black";
              }

              tdInnerTD.appendChild(imgTag);
              tdParent.style.backgroundColor = Sections.style.backgroundColor; //as per anuj comments
            } else {
              checkLeftRightCenter(
                yellowinnerChild,
                tdParent,
                firstTablePadding,
                tbInner
              );
              let trInnerRow = tbInner.insertRow();
              let tdInnerTD = trInnerRow.insertCell();
              trInnerRow.style.backgroundColor =
                yellowinnerChild.style?.backgroundColor;

              if (insideyellow.tagName === "TABLE") {
                constructTable(yellowinnerChild, insideyellow, tdInnerTD);
              } else if (yellowInnerAttr === "LAYOUT_LIST") {
                // cunstructList(insideyellow);
                cunstructList(insideyellow, tbInner);
                trInnerRow.remove();
                // tdInnerTD.innerHTML = insideyellow.innerHTML;
              } else {
                tdInnerTD.innerHTML = insideyellow.innerHTML;
              }
              tdInnerTD.style = insideyellow.style?.cssText;
              tdInnerTD.style.removeProperty("top");
              tdInnerTD.style.removeProperty("left");
              tdInnerTD.style.removeProperty("z-index");
              tdInnerTD.style.padding = yellowinnerChild.style?.padding;
              tdParent.style.backgroundColor = Sections.style.backgroundColor;

              // parseInt(insideyellow?.style?.left) > 30 &&
              // parseInt(insideyellow?.style?.left) < 300
              //   ? (tdInnerTD.style.textAlign = "center")
              //   : (tdInnerTD.style.textAlign = "left");

              // if (parseInt(insideyellow?.style?.left) > 350)
              //   tdInnerTD.style.textAlign = "right";

              if (
                indexInsideyellow < yellowinnerChild?.childNodes?.length - 1 &&
                getGapBetweenDivs(
                  insideyellow,
                  yellowinnerChild.childNodes[indexInsideyellow + 1]
                ) > 3
              ) {
                let tbInnerRow = tbInner.insertRow();
                let tdforTR = tbInnerRow.insertCell();

                tdforTR.style.height =
                  getGapBetweenDivs(
                    insideyellow,
                    yellowinnerChild.childNodes[indexInsideyellow + 1]
                  ) + "px";
                // tdforTR.style.background = "blue";
              }
              let value =
                //  parseInt(Sections.style.top) -
                Sections.clientHeight -
                (Sections.childNodes[Sections.childNodes.length - 1]
                  .clientHeight +
                  parseInt(
                    Sections.childNodes[Sections.childNodes.length - 1].style
                      .top
                  ));
              // Last child and section bottom gap
              if (
                indexInsideyellow === yellowinnerChild.childNodes.length - 1 &&
                value > 3
              ) {
                let trInner = tbInner.insertRow();
                let tdInner = trInner.insertCell();
                tdInner.style.height = value + "px";
                // tdforTR.style.backgroundColor = "black";
              }
            }
          }
        );
      }
      tdParent.append(tbInner); // insert contnet here CTAimpDeepa

      // if (yellowChildIndex > 0) {
      //   let trnew = tbl.insertRow();
      //   let td = trnew.insertCell();
      //   td.append(tbInner);
      // } else {
      //   let td = tr.insertCell();
      //   td.append(tbInner);
      // }
      //LookFor right Gap
      // if (
      //   600 -
      //     parseInt(yellowinnerChild.style.left) -
      //     parseInt(yellowinnerChild.style.width) -
      //     firstTablePadding >
      //   0
      // ) {
      //   let td = tr.insertCell();
      //   td.style.width =
      //     600 -
      //     parseInt(yellowinnerChild.style.left) -
      //     parseInt(yellowinnerChild.style.width) -
      //     firstTablePadding +
      //     "px";
      //   td.className = "rightPadding";
      //   // td.style.backgroundColor = "red";
      // }
    });

    //return collectTable;
  };

  const getGapBetweenDivs = (child, parent) => {
    if (parseInt(parent?.style?.top) !== parseInt(child.style?.top || 0))
      return (
        parseInt(parent?.style?.top) -
        parseInt(child.style?.top) -
        child.clientHeight
      );
  };

  // const checkForLeftGap = (columnsArray, objArray, eachColumnIndex) => {
  //   if (eachColumnIndex > 0) {
  //     let perviousObjArray = columnsArray.objstyles.filter(function (el) {
  //       return el.columNumber === columnsArray.column[eachColumnIndex - 1];
  //     });
  //     perviousObjArray.sort(function (a, b) {
  //       return b.widthLeft - a.widthLeft;
  //     });
  //     let valueLeast = [...objArray].sort(function (a, b) {
  //       return a.left - b.left;
  //     });

  //     if (
  //       eachColumnIndex === columnsArray.column[columnsArray.column.length - 1]
  //     ) {
  //       return {
  //         leftValue: Math.abs(
  //           valueLeast[0].left -
  //             perviousObjArray[0].widthLeft -
  //             firstTablePadding
  //         ),
  //         lastColum: Math.abs(
  //           600 - valueLeast[0].widthLeft - firstTablePadding
  //         ),
  //       };
  //     } else {
  //       return Math.abs(
  //         valueLeast[0].left - perviousObjArray[0].widthLeft - firstTablePadding
  //       );
  //     }
  //   } else {
  //     let valueLeast = [...objArray].sort(function (a, b) {
  //       return a.left - b.left;
  //     });
  //     return Math.abs(valueLeast[0].left - firstTablePadding);
  //   }
  // };

  // const constructMultipleChildInner = (Sections) => {
  //   let tbl = document.createElement("table");
  //   tbl.setAttribute("cellspacing", "0");
  //   tbl.setAttribute("cellpadding", "0");
  //   Sections.childNodes.forEach((yellowinnerChild, yellowChildIndex) => {
  //     if (yellowChildIndex === 0) {
  //       let tr = tbl.insertRow();
  //       let tdforTR = tr.insertCell();

  //       tdforTR.style.height =
  //         checktopGapBetweenDivs(yellowinnerChild, Sections) + "px";
  //     }
  //     let tbInner = document.createElement("table");
  //     tbInner.setAttribute("cellspacing", "0");
  //     tbInner.setAttribute("cellpadding", "0");
  //     tbInner.style.width = "100%";

  //     // second leve: goinside the yellow children
  //     yellowinnerChild.childNodes.forEach((insideyellow, indexInsideyellow) => {
  //       if (yellowChildIndex === 0) {
  //         let tbInnerRow = tbInner.insertRow();
  //         let tdforTR = tbInnerRow.insertCell();
  //         tdforTR.style.height =
  //           checktopGapBetweenDivs(insideyellow, yellowinnerChild) || 0 + "px";
  //       }
  //       if (insideyellow.tagName === "IMG") {
  //         var imgTag = document.createElement("IMG");

  //         imgTag.setAttribute("src", insideyellow.currentSrc);
  //         imgTag.setAttribute("width", insideyellow.width + "px");
  //         let trInnerRow = tbInner.insertRow();
  //         let tdInnerTD = trInnerRow.insertCell();
  //         // to make image at center
  //         //  tdInnerTD.setAttribute("align", "center");

  //         tdInnerTD.appendChild(imgTag);
  //       } else {
  //         let trInnerRow = tbInner.insertRow();
  //         let tdInnerTD = trInnerRow.insertCell();
  //         tdInnerTD.innerHTML = insideyellow.innerHTML;
  //         tdInnerTD.fontSize = insideyellow?.style?.fontSize;
  //         tdInnerTD.fontWeight = insideyellow?.style?.fontWeight;
  //         tdInnerTD.style.fontFamily = insideyellow?.style?.fontFamily;
  //         tdInnerTD.style.color = insideyellow?.style?.color || "black";

  //         if (indexInsideyellow < yellowinnerChild.childNodes.length) {
  //           let tbInnerRow = tbInner.insertRow();
  //           let tdforTR = tbInnerRow.insertCell();
  //           tdforTR.style.height =
  //             checktopGapBetweenDivs(
  //               insideyellow,
  //               yellowinnerChild.childNodes[indexInsideyellow + 1]
  //             ) || 0 + "px";
  //         }
  //       }
  //     });
  //     let tr = tbl.insertRow();
  //     let td = tr.insertCell();
  //     td.append(tbInner);

  //     console.log(td);
  //   });
  //   return tbl;
  // };
  // const constructTable = (columnsArray, Sections) => {
  //   let tbl = document.createElement("table");
  //   tbl.setAttribute("cellspacing", "0");
  //   tbl.setAttribute("cellpadding", "0");
  //   tbl.style.width = "100%";
  //   let tr = tbl.insertRow();
  //   tbl.setAttribute("name", "innerSections-borderline");
  //   let tblforColum = "";
  //   //  td.innerHTML = "SriP
  //   //As per COlumns create Tables

  //   /* $$$$$$$$$
  //   Find which all sections comes into each of these columns
  //   $$$$$$$$$*/
  //   if (columnsArray.objstyles.length > columnsArray.column.length)
  //     basedColumnsGroupSections(columnsArray);

  //   columnsArray.objstyles.sort(function (a, b) {
  //     return a.columNumber - b.columNumber;
  //   });

  //   columnsArray.column.forEach(function (eachColumn, eachColumnIndex) {
  //     let objArray = columnsArray.objstyles.filter(function (el) {
  //       return el.columNumber === columnsArray.column[eachColumnIndex];
  //     });

  //     // call function for left value
  //     //left  gap calculation
  //     let leftEmptyValue = checkForLeftGap(
  //       columnsArray,
  //       objArray,
  //       eachColumnIndex
  //     );

  //     if (leftEmptyValue > 0 || leftEmptyValue?.leftValue) {
  //       let tdgap = tr.insertCell();

  //       tdgap.setAttribute(
  //         "width",
  //         (leftEmptyValue?.leftValue || leftEmptyValue) + "px"
  //       );
  //       tdgap.className = "verticalGap";
  //     }
  //     //end of left  gap calculation

  //     tblforColum = document.createElement("table");
  //     tblforColum.style.width = "100%";
  //     //Sort for top values in a column

  //     objArray.sort(function (a, b) {
  //       return a.top - b.top;
  //     });

  //     objArray.forEach((obj, indexObj) => {
  //       if (indexObj === 0) {
  //         tblforColum.setAttribute("cellspacing", "0");
  //         tblforColum.setAttribute("cellpadding", "0");

  //         let valueHeight = checktopGapBetweenDivs(
  //           Sections.childNodes[obj.index],
  //           Sections
  //         );
  //         let trforColumnROW = tblforColum.insertRow();
  //         let trforColumnTD = trforColumnROW.insertCell();

  //         trforColumnTD.style.height = valueHeight + "px";
  //         // trforColumnTD.setAttribute("test", "sripada");
  //       }

  //       console.log(Sections, objArray);

  //       //             let testforImage = eachLine.getElementsByTagName("img");
  //       //             if (testforImage.length) {
  //       //               var imgTag = document.createElement("IMG");

  //       //               imgTag.setAttribute("src", testforImage[0].currentSrc);
  //       //               imgTag.setAttribute("width", testforImage[0].width + "px");

  //       //               tdsub.appendChild(imgTag);

  //       if (
  //         Sections.childNodes[obj.index].querySelectorAll('[child="inner"]')
  //           .length > 0 &&
  //         Sections.childNodes[obj.index].attributes?.child?.value === "inner"
  //       ) {
  //         let result = constructMultipleChildInner(
  //           Sections.childNodes[obj.index]
  //         );
  //         // let trforColumnROW = tblforColum.insertRow();
  //         // let trforColumnTD = trforColumnROW.insertCell();
  //         // trforColumnROW.className = "stackImage";

  //         let td = tr.insertCell();
  //         td.className = "stackColumn";
  //         // td.style.width =
  //         //   Sections.childNodes[obj.index].getElementsByTagName("img")[0]
  //         //     .width + "px";
  //         if (leftEmptyValue?.leftValue) {
  //           let tdgapNext = tr.insertCell();

  //           // tdgapNext.setAttribute("width", leftEmptyValue?.lastColum + "px");
  //           tdgapNext.className = "verticalGap";
  //         }
  //         td.append(result);
  //       } else if (
  //         Sections.childNodes[obj.index].getElementsByTagName("img")[0]
  //           ?.tagName === "IMG"
  //       ) {
  //         var imgTag = document.createElement("IMG");

  //         imgTag.setAttribute(
  //           "src",
  //           Sections.childNodes[obj.index].getElementsByTagName("img")[0]
  //             .currentSrc
  //         );
  //         imgTag.setAttribute(
  //           "width",
  //           Sections.childNodes[obj.index].getElementsByTagName("img")[0]
  //             .width + "px"
  //         );
  //         imgTag.className = "columnImage";
  //         // trforColumnTD.innerHTML = "sriPada 33";
  //         let trforColumnROW = tblforColum.insertRow();
  //         let trforColumnTD = trforColumnROW.insertCell();
  //         trforColumnROW.className = "stackImage";
  //         trforColumnTD.appendChild(imgTag);

  //         let td = tr.insertCell();
  //         td.className = "stackColumn";
  //         // td.style.width =
  //         //   Sections.childNodes[obj.index].getElementsByTagName("img")[0]
  //         //     .width + "px";
  //         if (leftEmptyValue?.leftValue) {
  //           let tdgapNext = tr.insertCell();

  //           tdgapNext.setAttribute("width", leftEmptyValue?.lastColum + "px");
  //           tdgapNext.className = "verticalGap";
  //         }
  //         td.append(tblforColum);
  //       } else {
  //         Sections.childNodes[obj.index].childNodes.forEach(
  //           (insideYellowBorder, yellowIndex) => {
  //             let trforColumnROW = tblforColum.insertRow();
  //             trforColumnROW.className = "stackText";
  //             let trforColumnTD = trforColumnROW.insertCell();
  //             trforColumnTD.innerHTML = insideYellowBorder.innerHTML;
  //             trforColumnTD.style.fontSize =
  //               insideYellowBorder?.style?.fontSize ||
  //               Sections.childNodes[obj.index].style?.fontSize ||
  //               "inherit";
  //             trforColumnTD.style.fontFamily =
  //               insideYellowBorder?.style?.fontFamily ||
  //               Sections.childNodes[obj.index].style?.fontFamily ||
  //               "inherit";
  //             trforColumnTD.style.fontFamily =
  //               insideYellowBorder?.style?.fontFamily || "inherit";
  //             trforColumnTD.style.color =
  //               insideYellowBorder?.style?.color || "black";
  //           }
  //         );

  //         let td = tr.insertCell();
  //         td.className = "stackColumn";
  //         td.append(tblforColum);
  //         if (leftEmptyValue?.leftValue) {
  //           let tdgapNext = tr.insertCell();

  //           tdgapNext.setAttribute("width", leftEmptyValue?.lastColum + "px");
  //           tdgapNext.className = "verticalGap";
  //         }
  //       }
  //     });
  //   });

  //   // Sections.forEach((yelloInnerChild, yellowIndex) => {
  //   //    ;
  //   //   let tr = tbl.insertRow();
  //   //   let td = tr.insertCell();
  //   //   if (
  //   //     yellowIndex === 0 &&
  //   //     checktopGapBetweenDivs(yelloInnerChild, Sections)
  //   //   ) {
  //   //     td.style.height =
  //   //       checktopGapBetweenDivs(yelloInnerChild, Sections) + "px";
  //   //     tr = tbl.insertRow();
  //   //     td = tr.insertCell();
  //   //   }
  //   // });
  //   //columnsArray.check;
  //   //document.getElementById("tableDom").appendChild(tbl);
  //   let value =
  //     //  parseInt(Sections.style.top) -
  //     Sections.clientHeight -
  //     (Sections.childNodes[Sections.childNodes.length - 1].clientHeight +
  //       parseInt(
  //         Sections.childNodes[Sections.childNodes.length - 1].style.top
  //       ));
  //   let trnew = tbl.insertRow();
  //   let tdforTR = trnew.insertCell();
  //   tdforTR.style.height = value + "px";
  //   return tbl;
  // };

  const basedColumnsGroupSections = (columsArray) => {
    columsArray.objstyles.forEach((eachObj, eachObjIndex) => {
      if (
        !eachObj.hasOwnProperty("columNumber") &&
        eachObjIndex < columsArray.objstyles.length - 1
      ) {
        if (columsArray.objstyles[eachObjIndex + 1].left < eachObj.widthLeft) {
          eachObj.columNumber = eachObjIndex + 1;
        }
      }
      // if (columsArray.objstyles[eachObjIndex + 1].left < eachObj.widthLeft)
      //   eachObj.widthLeft.coulm;
    });
  };
  const checktopGapBetweenDivs = (child, parent) => {
    if (parseInt(parent?.style?.top) !== parseInt(child?.style?.top || 0))
      return parseInt(child?.style?.top || 0);
  };

  const calculateLeftAlign = (eachSection, leftValue4Table) => {
    // if (parseInt(eachSection.style.left) > 150) return "center";
    // else
    if (parseInt(eachSection.style.left) - leftValue4Table > 4) {
      return parseInt(eachSection.style.left) - leftValue4Table;
    }
    return 0;
  };
  const getleastLeftValue = (section) => {
    let leftValue = 0;
    section.childNodes.forEach((eachsection, index) => {
      if (index === 0) leftValue = parseInt(eachsection.style.left);
      if (leftValue > parseInt(eachsection.style.left))
        leftValue = parseInt(eachsection.style.left);
    });
    return leftValue;
  };

  const innerMargin = (
    eachSection,
    innerChild,
    ind,
    section,
    innerChildIndex
  ) => {
    let nextSection = innerChild.childNodes[ind + 1];

    if (
      innerChildIndex === section.childNodes.length - 1 &&
      ind === innerChild.childNodes.length - 1
    ) {
      return (
        section.clientHeight -
        (eachSection.clientHeight +
          parseInt(eachSection.style.top) +
          parseInt(innerChild.style.top))
      );
    } else if (
      parseInt(nextSection.style.top) -
      (parseInt(eachSection.style.top || 0) +
        parseInt(eachSection.clientHeight))
    ) {
      return (
        parseInt(nextSection.style.top || 0) -
        (parseInt(eachSection.style.top || 0) +
          parseInt(eachSection.clientHeight))
      );
    }
  };
  const downloadHtml = () => {
    let html = responsiveHTML();
    let htmlContent = tableHtml(html.outerHTML);
    // // Log the HTML content to the console (you can perform further actions here)
    // console.log("Exported HTML content:", htmlContent);
    // Example: Save HTML content to a file
    // You can use any method to save the content, like Blob, FileSaver.js, etc.
    var blob = new Blob([htmlContent], { type: "text/html" });
    var link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);

    link.download = props.isDownloadZipDSP
      ? props.fileDetails.name + "_DSP.html"
      : props.fileDetails.name + ".html";

    link.click();
  };

  const handleTypeChange = async (e) => {
    setSelectedType(e.target.value);
    const body = {
      deck_id: props.fileDetails.deck_id,
      conversionId: props.fileDetails?.file_conversion_pages[0].conversion_id,
    };
    try {
      const response = await getMasterSlides(body);
      setMasterSlideList(response.data);
    } catch (err) {
      toast.error(err.response.data);
    }
  };

  const getUserEmail = () => {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    return userInfo?.email;
  };

  const getPageAndFileName = (name) => {
    let fileName = props.fileDetails?.file_conversion_pages[0].image_path
      ?.split("/")
      .pop()
      .split(".")
      .slice(0, -1)
      .join(".")
      .split(".")
      .slice(0, -1)
      .join(".");
    if (name === "page") {
      fileName = `${fileName}_${props.fileDetails.pageNumber}`;
    }
    return fileName;
  };
  if (isHTMLPublishing) {
    return (
      <div className="file-upload-loader">
        <lottie-player
          src={JSON.stringify(require("../../Images/97203.json"))}
          background="transparent"
          speed="1"
          style={{ width: "226px", height: "127px" }}
          loop
          autoplay
        ></lottie-player>
        <span>This process may take a while. </span>
      </div>
    );
  }

  return (
    <div className="mainDiv">
      <div className="metaDataContainer">
        <header className="heading">User Generated HTML</header>
        <div
          dangerouslySetInnerHTML={{ __html: htmlString }}
          className="user-generated-html"
        ></div>
      </div>
      <div className="metaDataContainer">
        <header className="heading">Add Metadata</header>
        <div className="box">
          {props.isPublishClicked === 1 ? (
            <>
              <div className="enterDetailsBox">
                <span className="enterDetails">Enter basic details</span>
                {!emailNameValidity ? (
                  <>
                    <span className="errorMessage">
                      Enter only Alpha Numeric values
                    </span>
                  </>
                ) : null}
                <CustomizedSwitches />
              </div>
              <div>
                <FormControl
                  fullWidth
                  sx={{ m: 1 }}
                  variant="filled"
                  className="emailName"
                >
                  <TextField
                    error={!emailNameValidity}
                    id="filled-adornment-amount"
                    onChange={emailNameChange}
                    label="Name"
                    variant="outlined"
                    value={emailName}
                    disabled={isDisabled()}
                  />
                </FormControl>

                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "space-between",
                  }}
                >
                  <FormControl className="metadata-dropdowns">
                    <InputLabel id="demo-simple-select-helper-label">
                      Brand
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      value={selectedBrand}
                      disabled={isDisabled()}
                      label="Age"
                      onChange={(e) => onChangeBrand(e.target.value)}
                      variant="outlined"
                      sx={{
                        "& .MuiSelect-iconOutlined": {
                          display: selectedBrand ? "none" : "",
                        },
                        "&.Mui-focused .MuiIconButton-root": {
                          color: "primary.main",
                        },
                      }}
                      endAdornment={
                        <IconButton
                          sx={{
                            visibility: selectedBrand ? "visible" : "hidden",
                            position: "absolute",
                            right: 0,
                          }}
                          onClick={clearSelection}
                          disabled={isDisabled()}
                        >
                          <ClearIcon fontSize="small" />
                        </IconButton>
                      }
                    >
                      {brands?.length &&
                        brands?.map((item, index) => {
                          return (
                            <MenuItem
                              key={item.id}
                              value={
                                fileContext.file.application_type === 1
                                  ? item.id
                                  : item.name
                              }
                            >
                              {item.name}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                  <FormControl className="metadata-dropdowns">
                    <InputLabel id="demo-simple-select-helper-label">
                      Campaign
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      value={selectedCampaigns}
                      label="Age"
                      onChange={(e) => {
                        setSelectedCampaigns(e.target.value);
                      }}
                      variant="outlined"
                      disabled={!selectedBrand || isDisabled()}
                      sx={{
                        "& .MuiSelect-iconOutlined": {
                          display: selectedCampaigns ? "none" : "",
                        },
                        "&.Mui-focused .MuiIconButton-root": {
                          color: "primary.main",
                        },
                      }}
                      endAdornment={
                        <IconButton
                          sx={{
                            visibility: selectedCampaigns
                              ? "visible"
                              : "hidden",
                            position: "absolute",
                            right: 0,
                          }}
                          onClick={() => setSelectedCampaigns("")}
                          disabled={isDisabled()}
                        >
                          <ClearIcon fontSize="small" />
                        </IconButton>
                      }
                    >
                      {filteredCampaigns?.length !== 0 &&
                        filteredCampaigns?.map((item, index) => {
                          return (
                            <MenuItem
                              key={item._id || item.id}
                              id={item._id || item.id}
                              value={
                                fileContext?.file?.application_type === 1
                                  ? item?._id || item?.id
                                  : item?.name || item?._name
                              }
                            >
                              {item.name}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>

                  {fileContext.file.application_type ===
                  APPLICATION_TYPE_EMAILER ? (
                    <>
                      <FormControl className="metadata-dropdowns">
                        <InputLabel id="demo-simple-select-helper-label">
                          Region
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-helper-label"
                          id="demo-simple-select-helper"
                          value={selectedRegion}
                          disabled={isDisabled()}
                          label="Age"
                          onChange={(e) => {
                            // console.log("selected regions is " + selectedRegion);
                            onRegionChange(e.target.value);
                          }}
                          variant="outlined"
                          sx={{
                            "& .MuiSelect-iconOutlined": {
                              display: selectedRegion ? "none" : "",
                            },
                            "&.Mui-focused .MuiIconButton-root": {
                              color: "primary.main",
                            },
                          }}
                          endAdornment={
                            <IconButton
                              sx={{
                                visibility: selectedRegion
                                  ? "visible"
                                  : "hidden",
                                position: "absolute",
                                right: 0,
                              }}
                              onClick={() => {
                                setSelectedRegion("");
                                setSelectedCountries("");
                              }}
                              disabled={isDisabled()}
                            >
                              <ClearIcon fontSize="small" />
                            </IconButton>
                          }
                        >
                          {regions?.length &&
                            regions?.map((item, index) => {
                              return (
                                <MenuItem
                                  key={item.id}
                                  id={item.id}
                                  value={item.name}
                                >
                                  {item.name}
                                </MenuItem>
                              );
                            })}
                        </Select>
                      </FormControl>
                      <FormControl className="metadata-dropdowns">
                        <InputLabel id="demo-simple-select-helper-label">
                          Country
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-helper-label"
                          id="demo-simple-select-helper"
                          value={selectedCountries}
                          label="Age"
                          onChange={(e) => setSelectedCountries(e.target.value)}
                          variant="outlined"
                          disabled={!selectedRegion || isDisabled()}
                          sx={{
                            "& .MuiSelect-iconOutlined": {
                              display: selectedCountries ? "none" : "",
                            },
                            "&.Mui-focused .MuiIconButton-root": {
                              color: "primary.main",
                            },
                          }}
                          endAdornment={
                            <IconButton
                              sx={{
                                visibility: selectedCountries
                                  ? "visible"
                                  : "hidden",
                                position: "absolute",
                                right: 0,
                              }}
                              onClick={() => setSelectedCountries("")}
                              disabled={isDisabled()}
                            >
                              <ClearIcon fontSize="small" />
                            </IconButton>
                          }
                        >
                          {filteredCountries?.length &&
                            filteredCountries?.map((item, index) => {
                              return (
                                <MenuItem
                                  key={item.id || item._id}
                                  id={item.id || item._id}
                                  value={
                                    fileContext.file.application_type === 1
                                      ? item.id || item._id
                                      : item.name || item._name
                                  }
                                >
                                  {item.name}
                                </MenuItem>
                              );
                            })}
                        </Select>
                      </FormControl>
                    </>
                  ) : (
                    <>
                      <FormControl className="metadata-dropdowns">
                        <InputLabel id="demo-simple-select-helper-label">
                          Device
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-helper-label"
                          id="demo-simple-select-helper"
                          value={selectedDevice}
                          disabled={isDisabled()}
                          label="Age"
                          onChange={(e) => {
                            setSelectedDevice(e.target.value);
                          }}
                          variant="outlined"
                          sx={{
                            "& .MuiSelect-iconOutlined": {
                              display: selectedDevice ? "none" : "",
                            },
                            "&.Mui-focused .MuiIconButton-root": {
                              color: "primary.main",
                            },
                          }}
                          endAdornment={
                            <IconButton
                              sx={{
                                visibility: selectedDevice
                                  ? "visible"
                                  : "hidden",
                                position: "absolute",
                                right: 0,
                              }}
                              onClick={() => {
                                setSelectedDevice("");
                              }}
                              disabled={isDisabled()}
                            >
                              <ClearIcon fontSize="small" />
                            </IconButton>
                          }
                        >
                          {devices?.length &&
                            devices?.map((item, index) => {
                              return (
                                <MenuItem
                                  key={item._id}
                                  id={item._id}
                                  value={item._id}
                                >
                                  {item.name}
                                </MenuItem>
                              );
                            })}
                        </Select>
                      </FormControl>
                      <FormControl className="metadata-dropdowns">
                        <InputLabel id="demo-simple-select-helper-label">
                          Dimension
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-helper-label"
                          id="demo-simple-select-helper"
                          value={selectedDimension}
                          disabled={isDisabled()}
                          label="Age"
                          onChange={(e) => setSelectedDimension(e.target.value)}
                          variant="outlined"
                          // disabled={!selectedRegion}
                          sx={{
                            "& .MuiSelect-iconOutlined": {
                              display: selectedCountries ? "none" : "",
                            },
                            "&.Mui-focused .MuiIconButton-root": {
                              color: "primary.main",
                            },
                          }}
                          endAdornment={
                            <IconButton
                              sx={{
                                visibility: selectedDimension
                                  ? "visible"
                                  : "hidden",
                                position: "absolute",
                                right: 0,
                              }}
                              onClick={() => setSelectedDimension("")}
                              disabled={isDisabled()}
                            >
                              <ClearIcon fontSize="small" />
                            </IconButton>
                          }
                        >
                          {dimensions?.length &&
                            dimensions.map((item, index) => {
                              return (
                                <MenuItem
                                  key={item._id}
                                  id={item._id}
                                  value={item._id}
                                >
                                  {item.name}
                                </MenuItem>
                              );
                            })}
                        </Select>
                      </FormControl>
                      <FormControl className="metadata-dropdowns">
                        <InputLabel id="demo-simple-select-helper-label">
                          Market
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-helper-label"
                          id="demo-simple-select-helper"
                          value={selectedMarket}
                          disabled={isDisabled()}
                          label="Age"
                          onChange={(e) => setSelectedMarket(e.target.value)}
                          variant="outlined"
                          sx={{
                            "& .MuiSelect-iconOutlined": {
                              display: selectedMarket ? "none" : "",
                            },
                            "&.Mui-focused .MuiIconButton-root": {
                              color: "primary.main",
                            },
                          }}
                          endAdornment={
                            <IconButton
                              sx={{
                                visibility: selectedMarket
                                  ? "visible"
                                  : "hidden",
                                position: "absolute",
                                right: 0,
                              }}
                              onClick={() => setSelectedMarket("")}
                              disabled={isDisabled()}
                            >
                              <ClearIcon fontSize="small" />
                            </IconButton>
                          }
                        >
                          {market?.length &&
                            market.map((item, index) => {
                              return (
                                <MenuItem
                                  key={item._id}
                                  id={item._id}
                                  value={item._id}
                                >
                                  {item.name}
                                </MenuItem>
                              );
                            })}
                        </Select>
                      </FormControl>
                      <FormControl className="metadata-dropdowns">
                        <InputLabel id="demo-simple-select-helper-label">
                          Type
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-helper-label"
                          id="demo-simple-select-helper"
                          value={selectedType}
                          disabled={
                            (isReorder && checkIfPublished()) || deckId === null
                          }
                          label="Age"
                          onChange={(e) => handleTypeChange(e)}
                          variant="outlined"
                          sx={{
                            "& .MuiSelect-iconOutlined": {
                              display: selectedType ? "none" : "",
                            },
                            "&.Mui-focused .MuiIconButton-root": {
                              color: "primary.main",
                            },
                          }}
                          endAdornment={
                            <IconButton
                              sx={{
                                visibility: selectedType ? "visible" : "hidden",
                                position: "absolute",
                                right: 0,
                              }}
                              onClick={() => {
                                setSelectedType("");
                                setSelectedMasterSlide("");
                              }}
                              disabled={
                                (isReorder && checkIfPublished()) ||
                                deckId === null
                              }
                            >
                              <ClearIcon fontSize="small" />
                            </IconButton>
                          }
                        >
                          {typesList?.length &&
                            typesList.map((item, index) => {
                              return (
                                <MenuItem
                                  key={item._id}
                                  id={item._id}
                                  value={item.value}
                                >
                                  {item.name}
                                </MenuItem>
                              );
                            })}
                        </Select>
                      </FormControl>
                      <FormControl className="metadata-dropdowns">
                        <InputLabel id="demo-simple-select-helper-label">
                          Master Slide
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-helper-label"
                          id="demo-simple-select-helper"
                          value={selectedMasterSlide}
                          disabled={
                            !selectedType ||
                            (isReorder && checkIfPublished()) ||
                            deckId === null
                          }
                          label="Age"
                          onChange={(e) =>
                            setSelectedMasterSlide(e.target.value)
                          }
                          variant="outlined"
                          sx={{
                            "& .MuiSelect-iconOutlined": {
                              display: selectedMasterSlide ? "none" : "",
                            },
                            "&.Mui-focused .MuiIconButton-root": {
                              color: "primary.main",
                            },
                          }}
                          endAdornment={
                            <IconButton
                              sx={{
                                visibility: selectedMasterSlide
                                  ? "visible"
                                  : "hidden",
                                position: "absolute",
                                right: 0,
                              }}
                              onClick={() => setSelectedMasterSlide("")}
                              disabled={
                                !selectedType ||
                                (isReorder && checkIfPublished()) ||
                                deckId === null
                              }
                            >
                              <ClearIcon fontSize="small" />
                            </IconButton>
                          }
                        >
                          {masterSlideList?.length &&
                            masterSlideList.map((item, index) => {
                              return (
                                <MenuItem
                                  key={item.slideid._id}
                                  id={item.slideid._id}
                                  value={item.slideid._id}
                                  onClick={() =>
                                    setSelectedMasterSlideData(item)
                                  }
                                >
                                  {item.slideid.name}
                                </MenuItem>
                              );
                            })}
                        </Select>
                      </FormControl>
                    </>
                  )}
                </div>
              </div>{" "}
            </>
          ) : (
            <>
              <List dense={true}>
                <ListItem>
                  <ListItemText
                    primary={"User Mail"}
                    secondary={getUserEmail()}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={"Page Name"}
                    secondary={getPageAndFileName("page")}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={"Presentation Id"}
                    secondary={props.fileDetails.deck_id}
                  />
                </ListItem>
              </List>
            </>
          )}
          {fileContext.file.application_type === APPLICATION_TYPE_EDETAIL && (
            <Button
              variant="contained"
              onClick={() => {
                if (props.isPublishClicked === 1) {
                  props.exportClicked();
                } else if (props.isPublishClicked === 2) {
                  props.exportDSPClicked();
                }
              }}
              disabled={
                props.isPublishClicked === 1
                  ? fileContext.publishBtnDisabled || !props.fileDetails
                  : props.fileDetails?.file_conversion_pages[0].status === 2
              }
              style={{ height: "45px", float: "right" }}
            >
              Submit
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}
export default AddMetadata;
